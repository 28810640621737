#app {
  .navbar {
    margin-bottom: 40px;
  }
  .card {
    margin-bottom: 20px;
  }
}

.card-header-action {
  float: left;
  padding-left: 8px;
  cursor: pointer;
}

.description {
  font-size: 80%;
}

#feedback {
  .alert-dismissible {
    padding-right: 0;
  }

  display: none;
}

#card-taxonomies {
  .title {
    margin-top: 10px;
  }
  .taxonomy {
    margin-left: 20px;

    .data {
      margin-left: 20px;
    }
  }
}

#app-version {
  color: #ccc;
}

#checklist-mode-details {
  height: 72px;

  .checklist-mode-limit {
    overflow: hidden;
    max-width: 20ch;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.clickable {
  cursor: pointer;
}
.panel-buttons {
  margin-top: -20px;
}
.panel-buttons .fa {
  padding-left: 15px;
}

#custom-checklist-modal {
  .modal-dialog {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-content {
    height: 100%;
  }
}

.pagination {
  flex-wrap: wrap;
}

#taxonomy-tabs-content.tab-content > .active {
  margin: 15px;

  .form-control {
    margin: 10px 0;
  }
  .taxonomy-form-control {
    display: flex;

    .fa-trash-alt {
      color: #bbb;
      margin: 23px 0 0 7px;
    }
  }
}
